const img = '';

export default [
  {
    img: img,
    title: 'January 2017',
    description: `Meeting again for the first time after graduating high school`,
  },
  {
    img: img,
    title: '5 March 2017',
    description: `Deciding to start dating`,
  },
  {
    img: img,
    title: 'January 2018',
    description: `First trip together to Thailand and have an awesome journey. (But thanks to our masseuse to help us through Thailand with walking all day long)`,
  },
  {
    img: img,
    title: 'August 2018',
    description: `Dinner date after being away on a business trip for a month`,
  },
  {
    img: img,
    title: '22 May 2022',
    description: `Propose her (soon) to be my wife. (Luckily she said yes)`,
  },
];
